:root {
  --pdf-background: var(--grey-2);
  --tools-header-background: var(--grey-3);
}

.App-pdf {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.App-pdf .header {
  width: 100%;
  height: 60px;
  padding: 8px 8px 8px 16px;
  box-sizing: border-box;
  background: #00a5e4;
  font-size: 1.2em;
  line-height: 44px;
  color: white;
}

.App-pdf .webviewer iframe{
  min-height: 500px;
}

.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.App .header {
  width: 100%;
  height: 100px;
  padding: 8px 8px 8px 16px;
  box-sizing: border-box;
  background: #00a5e4;
  font-size: 1.2em;
  line-height: 44px;
  color: white;
}

#scroll-view {
  bottom: 0;
  /* leave room for 100px header */
  height: 100%;
  width: 100%;
  overflow: auto;
  flex: 3;
  background-color: var(--pdf-background);
}

#viewer {
  margin: auto;
  flex-grow: 1;
  /*max-height: calc(100vh - 100px);*/
  /*overflow: auto;*/
}

/* width */
::-webkit-scrollbar{
  height: 5px;
  width: 5px;
  background: gray;
}

::-webkit-scrollbar-thumb:horizontal{
  border-radius: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #d7d6d6;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.pdf-wrap {
  max-height: calc(100vh - 100px);
  overflow-x: auto;
  overflow-y: hidden;
  flex: 0 0 auto;
  margin: 0 auto;
  max-width: calc((75% - 25px));
}

.pdf-show-col {
  flex-grow: 1;
  /* max-width: calc((75% - 25px));
  overflow: hidden;
  flex: 0 0 auto;
  margin: 0 auto; */
}

#main-column {
  width: 100%;
}

#tools {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background-color: var(--tools-header-background);
}

#tools button {
  background-color: var(--tools-header-background);
  fill: #757575;
  width: 35px;
  height: 35px;
  margin: 5px;
  border: none;
  outline: none;
  box-shadow: none;
}

#tools button:hover {
  background-color: #c7d2dd;
  border-radius: 4px;
}

.pageContainer {
  border: 1px solid #ececec;
  box-shadow: 5px 10px 18px #ececec;
  position: relative;
}

.flexbox-container {
  display: flex;
}

.task path {
  fill: red;
}

.datalist {
  display: flex;
}
.pin-mark {
  width: 30px;
}
.listData ul {
  list-style: none;
  margin: 0 5px 5px 5px;
  padding: 5px;
}
.listData ul li {
  margin-bottom: 15px;
  padding: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.list-item p {
  margin: 0;
  font-size: 13px;
}
.list-image {
  margin: 5px;
}
.right-side-list {
  padding: 20px;
  width: 250px;
  background: white;
  font-weight: 500;
}

/* #virtualListContainer {
  display: none;
}

#virtualListContainer:nth-child(2n):last-child,
#virtualListContainer:nth-child(4n) {
  display: block;
} */

#selectionrect.ui-selectable-helper {
  border: none !important;
}
/* #virtualListContainer {
  display: none;
}
#virtualListContainer:last-child {
  display: block;
} */