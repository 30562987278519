.features-section {
    .section-container {
        display: flex;
        flex-direction: row;
        padding: 100px 0px;
        width: 90%;
        margin: 0 auto;
        max-width: 1400px;
    }

    @media (max-width: 1200px) {
        .section-container {
            flex-direction: column;
            align-items: center;

            .animator-container {
                width: 100% !important;
            }
        }

        .section-image-container {
            margin: 10px 10px 90px !important;
        }

        .section-text-container {
            margin: 10px 10px 90px !important;
        }
    }

    @media (max-width: 680px) {
        .section-container {
            .section-image-container img {
                height: 200px;
            }
        }
    }

    .section-image-container {
        margin: 10px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        img {
            // border-radius: 50px;
            max-height: 350px;
        }
    }

    .section-text-container {
        margin: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0px 30px;

        h2 {
            margin: 0px 0px 20px;
            font-size: 48px;
            color: #4b4647;
            font-size: 35px;
            font-weight: 700;
            line-height: 1.25em;
        }

        h4 {
            margin: 0px 0px 20px;
            text-transform: none;
            font-size: 18px;
            color: #407797;
            font-weight: bold;
        }

        ul {
            list-style-type: disc;
            font-size: 16px;
            list-style-position: inside;

            li {
                margin: 10px 0px;
            }
        }

        .section-cta-btn {
            margin: 20px 0px 0px;
        }
    }
}

.feature-page-demo-section {
    .btn {
        border-radius: 32px;
        margin: 20px 0 0;
        border: none;
        padding: 10px 30px;
        font-size: 16px;
        text-transform: none;
    }

    .btn:hover {
        background-color: #e37600;
    }

    .contact-btn {
        background: #ff8400;
        color: white;
    }
}