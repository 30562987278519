.pricing-page-wrapper {
    padding-top: 20px;

    .pricing-container {
        max-width: 1565px;
        margin: 0 auto;
        padding: 32px;
        ;

        .plan-head-container {
            min-height: 200px;
        }

        .pricing-title {
            text-transform: capitalize;
            font-size: 16px;
            color: #f68342;
        }

        .pricing-subtext {
            font-size: 64px;
            font-weight: 600;
            line-height: 1.1;
        }

        .pricing-plan-container {
            display: flex;
            justify-content: space-evenly;

            @media screen and (max-width: 950px) {
                flex-direction: column;
                align-items: center;
            }

            .plan-card {
                padding: 16px 16px;
                margin: 24px;
                max-width: 400px;
                background-color: #fcf2ec;
                border-top: solid 4px #f68242;
                box-shadow: rgba(0, 0, 0, 0.25) 0px 25px 50px -12px; 
                width: 258px;


                .plan-title {
                    font-size: 24px;
                    font-weight: bold;
                }

                .plan-pricing {
                    display: flex;
                    align-items: flex-end;
                    margin: 16px 0px; 
                    

                    .plan-price {
                        font-size: 48px;
                        margin-right: 16px;
                    }
                }

                button {
                    margin-bottom: 24px;
                }

                .plan-helper-text {
                    margin-bottom: 16px;
                    min-height: 22px;
                }

                .plan-features {
                    ul {
                        list-style: none;

                        li {
                            margin-bottom: 8px;
                            font-weight: bold;

                            .anticon {
                                margin-right: 8px;
                            }
                        }
                    }
                }
            }
        }

        // Checkout Page Styles

        .checkout-container {
            display: flex;
            align-items: flex-start;
            margin-top: 60px;
            // justify-content: space-around;

            @media screen and (max-width: 1125px) {
                flex-direction: column-reverse;

                .order-summary {
                    margin: 0 0 64px !important;
                }
            }

            .checkout-subtext {
                font-size: 16px;
                font-weight: 600;
                line-height: 1.1;
            }

            .order-summary {
                width: 100%;
                max-width: 400px;
                background-color: #fcf2ec;
                padding: 32px;
                margin: 0px 32px 64px 64px;
                border-radius: 32px;

                .order-summary-item {
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;

                    .summary-title {
                        // opacity: 0.8;
                    }

                    .summary-value {
                        font-weight: 600;
                    }
                }
            }
        }

    }
}