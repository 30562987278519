.feature-page {
    img {
        width: 100%;
    }

    .home-container {
        width: 90%;
        margin: 0 auto;
        max-width: 1400px;
    }

    .banner-section {
        background: url("../../../images/banner.svg");
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        justify-content: flex-end;

        .home-container {
            text-align: right;
        }

        .banner-content {
            padding: 90px 20px;
            text-align: left;
            display: inline-block;

            .banner-inside-content {
                width: 434px;
                color: white;
                word-wrap: break-word;

                h1 {
                    color: white;
                    font-size: 28px;
                    margin-top: 0;
                    margin-bottom: 30px;
                }

                p {
                    margin-bottom: 50px;
                }
            }

            p {
                font-size: 16px;
            }

            .btn {
                border-radius: 32px;
                margin: 0;
                border: none;
                padding: 10px 30px;
                font-size: 16px;
                text-transform: none;
            }

            .btn:hover {
                background-color: #e37600;
            }

            .contact-btn {
                background: #ff8400;
                color: white;
            }

            .learn-btn {
                background-color: white;
                color: #1b8dd4;
            }
        }
    }
}