@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot?jcfks0');
  src: url('../fonts/icomoon.eot?jcfks0#iefix') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?jcfks0') format('truetype'),
  url('../fonts/icomoon.woff?jcfks0') format('woff'),
  url('../fonts/icomoon.svg?jcfks0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon', sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-folder:before {
  content: '\e931';
}
.icon-user:before {
  content: '\e930';
}
.icon-no-data:before {
  content: '\e92e';
}
.icon-pipeline:before {
  content: '\e92f';
}
.icon-settings:before {
  content: '\e92d';
}
.icon-archive:before {
  content: '\e92c';
}
.icon-project:before {
  content: '\e907';
}
.icon-log:before {
  content: '\e92a';
}
.icon-drawings:before {
  content: '\e91f';
}
.icon-mail:before {
  content: '\e923';
}
.icon-observations:before {
  content: '\e924';
}
.icon-photos:before {
  content: '\e925';
}
.icon-punch-list:before {
  content: '\e926';
}
.icon-rfi:before {
  content: '\e927';
}
.icon-schedule:before {
  content: '\e928';
}
.icon-specifications:before {
  content: '\e929';
}
.icon-external:before {
  content: '\e91e';
}
.icon-users:before {
  content: '\e920';
}
.icon-section:before {
  content: '\e921';
}
.icon-checklist:before {
  content: '\e922';
}
.icon-completed:before {
  content: '\e91d';
}
.icon-drag:before {
  content: '\e91c';
}
.icon-todo:before {
  content: '\e91a';
}
.icon-edit:before {
  content: '\e91b';
}
.icon-delete:before {
  content: '\e918';
}
.icon-file-attach:before {
  content: '\e919';
}
.icon-low:before {
  content: '\e917';
}
.icon-medium:before {
  content: '\e913';
}
.icon-none:before {
  content: '\e912';
}
.icon-very-low:before {
  content: '\e911';
}
.icon-high:before {
  content: '\e914';
}
.icon-very-high:before {
  content: '\e915';
}
.icon-critical:before {
  content: '\e916';
}
.icon-options:before {
  content: '\e910';
}
.icon-add:before {
  content: '\e902';
}
.icon-rotate:before {
  content: '\e903';
}
.icon-questionnaire:before {
  content: '\e904';
}
.icon-left:before {
  content: '\e905';
}
.icon-dots:before {
  content: '\e92b';
}
.icon-upload:before {
  content: '\e908';
}
.icon-lock:before {
  content: '\e909';
}
.icon-success:before {
  content: '\e90a';
}
.icon-attach:before {
  content: '\e90b';
}
.icon-ok:before {
  content: '\e90c';
}
.icon-info:before {
  content: '\e90d';
}
.icon-ballincourt:before {
  content: '\e90e';
}
.icon-notification:before {
  content: '\e901';
}
.icon-gallery:before {
  content: '\e900';
}
.icon-submittal:before {
  content: '\e90f';
}
.icon-external-settings:before {
  content: '\e906';
}
.icon-Info:before {
  content: "\e900";
  color: #009e1f;
}
