@import url(./styles/icon.css);

// html,
// body {
//   font-family: 'Inter';
// }

#root {
	--sjs-primary-backcolor: #FC8543;
	--sjs-primary-backcolor-dark: #f79f6f;
	.svgLoader {
		animation: spin 0.5s linear infinite;
		margin: 0 auto;
	}

	.divLoader {
		width: 100vw;
		height: 100vh;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 9999;
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}

		100% {
			transform: rotate(360deg);
		}
	}
}

.flex-start {
	justify-content: flex-start !important;
}

img {
	max-width: 100%;
}

.justify-content-between {
	justify-content: space-between !important;
}
.justify-content-evenly {
	justify-content: space-evenly!important;
}

.font-weight-400 {
	font-weight: 400;
  }
  
  .font-weight-500 {
	font-weight: 500;
  }
  
  .font-weight-600 {
	font-weight: 600;
  }
  
  .font-weight-700 {
	font-weight: 700;
  }
  

.ant-layout {
	display: flex;
	flex: auto;
	flex-direction: column;
	min-height: 0;
	background: #fff;

	.ant-breadcrumb {
		padding: 10px 60px;
		background: #ffffff !important;

		.last-title {
			line-height: 30px;
			color: rgba(0, 0, 0, 0.85);

			span {
				font-size: 20px;
			}

			img {
				width: 24px;
			}
		}

		.first-title {
			color: #909090;
			font-size: 20px;
			cursor: pointer;
		}
	}

	.ant-tabs-nav {
		background: #ffffff;
		padding: 0 60px;
		border-top: 1px solid #d3d3d3;
	}
}

.home-header {
	background-color: #f0f2f5;
}

.home-content {
	padding: 200px;
	text-align: center;

	h1 {
		font-size: 40px;
	}
}

.modal-fields {
	margin-bottom: 13px;
}

.auth-header {
	display: flex;
	justify-content: space-between;
	padding: 20px;
	height: 64px;

	div {
		display: flex;
		align-items: center;

		.ant-btn {
			display: flex;
			align-items: center;
			background: #f6f6f6;
			border-radius: 32px;

			margin: 0 5px;
		}

		.Draw-btn {
			background: #fc8543;
			color: white;
		}

		p {
			margin: 0;
			padding: 7px;
		}
	}
}

.plan-dropdown,
.version-dropdown {
	padding: 5px;
	background-color: #f6f6f6;
	margin-left: 22px;
	color: #444444;
	border-radius: 5px;

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		background-color: transparent;
		border: none;
	}
}

.pdf-list-pg {
	display: flex;
}

.ant-tabs-tab {
	justify-content: center;
}

.ant-tabs-left>.ant-tabs-nav {
	margin-top: 20px;

	.ant-tabs-tab {
		padding: 0;
	}
}

.tab-list .ant-tabs-tab {
	&.ant-tabs-tab-active {
		background-color: #feebe1;
		border-top-left-radius: 10px;
		border-bottom-left-radius: 10px;
		border-top-right-radius: 10px;

		&.ant-tabs-tab-disabled {
			background: transparent;
		}
	}

	.ant-tabs-tab-btn {
		padding: 5px 0;
	}
}

.tab-header {
	padding: 10px;
	text-align: left;
}

.ant-tabs-left>.ant-tabs-content-holder>.ant-tabs-content>.ant-tabs-tabpane {
	padding: 0;
}

.task-card.active {
	background-color: #f6f6f6;
	padding: 7px 14px;
	text-align: left;
}

.task-card {
	padding: 7px 12px;
	text-align: left;
	border: 1px solid #ececec;
}

.task-card-header {
	font-size: 14px;
	font-weight: 600;
	color: #fc8543;
	padding-left: 3px;
}

.task-card-desc {
	padding: 0 27px;
	font-size: 13px;
	margin-bottom: 0;
}

.ant-card-grid {
	padding: 12px;
}

.footer-button {
	display: flex;
	justify-content: space-between;
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	z-index: 1;

	.ant-radio-button-wrapper {
		background-color: #3b3b3ce5;
		border: none;
		padding: 3px 5px;

		svg {
			margin-top: 1px;
		}

		.fullsize {
			margin-right: 30px;
		}
	}

	.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
		background-color: #ffffff;
	}
}

.popover-icons {
	background-color: #474748;
	display: flex;
	flex-direction: column;
	padding: 4px 0;

	svg {
		margin: 5px;
		padding: 3px;
		border-bottom: 1px solid white;
	}
}

.ant-popover-inner-content {
	padding: 4px 4px;
}

.task-input {
	margin-left: 9px;
}

.task-popup-left-panel {
	display: flex;
}

.priority-list {
	display: flex;
	align-items: center;

	.priority-low {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 15px 20px;
		background: rgba(252, 133, 67, 0.1);
		border: 1px solid transparent;
	}

	.priority-medium {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 15px 20px;
		background: #f6f6f6;
		margin: 0 10px;
		border: 1px solid transparent;
	}

	.priority-high {
		display: flex;
		flex-direction: column;
		align-items: center;
		padding: 15px 20px;
		background: #f6f6f6;
		border: 1px solid transparent;
	}

	.active-priority-tab {
		border: 1px solid red;
	}
}

.model-lable {
	display: block;
}

.create-task-form {
	display: flex;
	flex-direction: column;
	//align-items: end;
	align-items: flex-end;
	margin-left: 30px;
	width: 100%;
}

.task-dates {
	display: flex;
}

.priority-list div {
	width: 87px;
}

.task-form-group {
	margin-bottom: 10px;
}

.task-dates .task-form-group+.task-form-group {
	margin-left: 10px;
}

.task-tabs {
	margin-top: 15px;
}

.assigned-users {
	display: flex;
	align-items: center;
	padding: 9px;

	svg {
		margin-right: 10px;
	}
}

.task-create-btn {
	background-color: #fc8543;
}

.link-popup {
	margin-top: 10px;

	.modal {
		margin: 0;
		border: 1px solid #d3d3d3;
	}

	.gotobtn {
		padding: 0 10px;

		.gotoPlan-btn {
			background: #fc8543;
			border-radius: 4px;
			border: none;
			width: 100%;
		}
	}

	.linkModalPlan {
		padding: 10px;
		margin: 10px 0;
		background: #f6f6f6;

		.linkModalImagePlan {
			background-color: white;
			padding: 15px;
		}
	}
}

.tab-list {
	overflow: hidden;
	background: white;

	.ant-badge-count {
		background-color: #fc8543;
	}

	.left-tab-image {
		width: 25px;
	}

	.tab-badge {
		border-bottom: 1px solid lightgray;
		padding-bottom: 10px;

		.ant-badge-count {
			// width: 18px;
			height: 18px;
			line-height: 18px;
		}
	}

	.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
		color: darkorange;
		text-shadow: 0 0 0.25px currentcolor;
	}

	.ant-tabs-tab.ant-tabs-tab-active.ant-tabs-tab-disabled .ant-tabs-tab-btn {
		color: rgba(0, 0, 0, 0.25) !important;
	}

	.ant-tabs-left {
		.ant-tabs-nav-wrap {
			p {
				margin-bottom: 0px;
			}
		}
	}

	.d-flex {
		font-size: 10px;
		align-items: center;

		img {
			width: 20px;
			margin: 0 2px;
		}
	}

	.allTab {
		overflow: auto;
		height: 75vh;

		.addedMarkup {
			padding: 0 10px;

			p {
				a {
					text-decoration: underline;
					text-underline-offset: 4px;
					font-size: 10px;
				}
			}
		}

		.issuesField {
			border-bottom: 1px solid lightgray;

			.markup-issues {
				padding: 5px 10px;

				.d-flex {
					align-items: flex-start;

					.markupIssue-img {
						width: 25px;
						// margin: 0 5px;
					}

					// .ant-checkbox-inner {
					//   background-color: red;
					//   border-color: red;
					// }
					.issues-details {
						h3 {
							font-weight: bold;
							margin-bottom: 0;
							font-size: 12px;
						}
					}

					p {
						font-size: 9px;
						align-items: center;

						img {
							width: 8px;
						}
					}

					.ant-badge-status-text {
						font-size: 10px;
					}

					.markupBall {
						width: 10px;
						margin-right: 5px;
					}
				}
			}
		}
	}

	.newTab {
		background: transparent;

		.ant-tabs-nav {
			background: none;
			margin-bottom: 0;
		}

		.ant-tabs-tab+.ant-tabs-tab {
			margin-left: 0 !important;
		}

		.ant-tabs-nav-wrap {
			//justify-content: end;
			justify-content: flex-end;
			background: #f6f6f6;
			margin-left: 49px;
			border-left: 1px solid #ececec;
		}

		.ant-tabs-nav::before {
			border-bottom: none;
		}

		.ant-tabs-tab {
			border: none;
			padding: 10px;
			margin-top: 10px;
		}

		.ant-tabs-tab-active {
			background: white;
		}

		.ant-tabs-tab:hover {
			color: black;
		}

		.addedMarkup {
			padding: 15px 0 10px 5px;
			margin-left: 49px;
			border-left: 1px solid #ececec;
			border-bottom: 1px solid #ececec;

			p {
				a {
					color: #3284ff;
					text-decoration: underline;
				}
			}
		}
	}
}

.drawings {
	padding: 10px 0;
	z-index: 0;

	.breadcrumb {
		margin: 0 0 0 3px;
	}

	.ant-breadcrumb-link {
		font-size: 20px;
		font-weight: 500;
		line-height: 32px;
	}

	.ant-breadcrumb-separator {
		color: #444444;
	}

	.ant-breadcrumb li:last-child {
		color: #080808;
	}

	.drawing-top {
		align-items: center;
	}

	.ant-badge-count {
		background: darkorange;
	}

	.category {
		background: #fc8543;
		padding: 8px;
		border-radius: 4px;
	}

	.listView {
		border: 1px solid #d9d9d9;
	}

	.ant-input-search {
		margin-right: 15px;
	}

	.ant-radio-button-wrapper {
		height: auto;
	}

	.drawings .listView {
		border: none;
	}

	span.ant-radio-button.ant-radio-button-checked {
		background-color: #fc8543;
	}
}

.container {
	max-width: 100%;
	margin: 0 auto;
	padding: 0 60px;
}

.industry-icon {
	padding: 7px;
	border-top-left-radius: 5px;
	border-bottom-left-radius: 5px;
	background: #f6f6f6;
}

.shortcut-options {
	width: 350px;
	max-width: 100%;
	text-align: center;
	padding: 20px 15px;

	.title {
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		color: #909090;
		text-align: left;
	}

	.shortcut-list {
		list-style-type: none;
		padding: 0;
		margin: 20px 0;
		display: flex;
		flex-wrap: wrap;

		li {
			width: 33.33%;
			flex: 0 0 auto;
			border-radius: 6px;
			padding: 20px;

			&:hover {
				background: #f6f6f6;
			}

			p {
				font-weight: 700;
				font-size: 12px;
				line-height: 16px;
				color: #080808;
				margin: 0;
			}

			.icon {
				color: #080808;
				font-size: 24px;
				margin-bottom: 15px;
			}

			&.active {
				background: #ffebe0;

				p,
				.icon {
					color: #fc8543;
				}
				svg path{
					background-color: #fc8543;
    stroke: #fc8543;
				}
			}

			.inactive {
				color: black;
				background: white;
				border-radius: 0;
			}
		}
	}
}

.project-list,
.RFI-list {
	background-color: #f6f6f6;

	.drawings {
		padding: 10px 0 30px;
		z-index: 0;
		position: absolute;
		right: 0;
		top: -21px;
		width: 250px;
	}

	.project-create-section {
		background-color: white;
		padding: 10px 0;
		border-bottom: 1px solid lightgray;

		.bc-nav-link {
			margin-right: 8px;
		}

		.create-btn {
			background: #fc8543;
			border-radius: 4px;
			color: white;
			margin-left: 10px;

			&:hover {
				background: #c54a08;
				border-color: #c54a08;
				box-shadow: none;
			}
		}
	}

	.listGridView {
		z-index: 0;
	}

	.project-list-data,
	.RFI-list-data {
		position: relative;
		z-index: 0;

		.drawingView {
			flex-flow: nowrap;
		}

		.ant-tabs-content-holder {
			width: 100%;
			margin: 0 auto;
			padding: 0 60px;
		}

		.ant-tabs-nav {
			.ant-tabs-nav-wrap {
				padding: 10px 60px;
				background-color: white;
				box-shadow: 0px 2px 4px rgba(141, 140, 140, 0.16);
			}

			.ant-tabs-nav-list {
				.ant-tabs-tab {
					color: #909090;
				}

				.ant-tabs-ink-bar {
					border-bottom: 3px solid #fc8543;
				}

				.ant-tabs-tab-active {
					.ant-tabs-tab-btn {
						color: black;
					}
				}
			}
		}
	}

	.project-list-table {
		.ant-tag-green {
			background-color: green;
			color: white;
		}

		.image-absolute {
			position: absolute;
			top: 10px;
			padding: 1px 15px;
			border-radius: 20px;
			color: white;
		}

		.th-icon {
			margin: 0 8px;
		}

		.ant-tag-red {
			background-color: red;
			color: white;
			border-radius: 20px;
		}

		.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
			height: 0;
		}

		.ant-table-thead>tr>th:nth-child(3) {
			width: 250px;
		}

		.ant-table-thead>tr>th:nth-child(5) {
			width: 200px;
		}

		.ant-table-thead>tr>th:nth-child(7) {
			width: 250px;
		}

		.last {
			color: #909090;
		}

		.testing {
			.ant-badge-count {
				background-color: lightgray;
				margin: 0 5px;
			}

			img {
				width: 119px;
			}

			.ant-tag {
				border-radius: 20px;
			}
		}

		.testing.project {
			display: flex;
			margin-top: 10px;

			div {
				display: flex;
				margin-right: 10px;
			}
		}

		.ant-table-tbody>tr>td {
			border-bottom: 5px solid #f6f6f6;
		}

		.ant-table-thead>tr>th {
			font-weight: 600;
			background: #ececec;
		}
	}

	.project-list-icon {
		span:not(.ant-radio-button) {
			display: flex;
			align-items: center;
			padding: 4px;
		}
	}

	.project-category-icon {
		span:not(.ant-radio-button) {
			display: flex;
			align-items: center;
			padding: 4px;
		}
	}
}

.drawing-list {
	background-color: #f6f6f6;
	height: 100%;

	.viewStyle {
		z-index: 0;
	}

	.project-list-icon {
		span:not(.ant-radio-button) {
			display: flex;
			align-items: center;
			padding: 4px;
		}
	}

	.project-category-icon {
		span:not(.ant-radio-button) {
			display: flex;
			align-items: center;
			padding: 4px;
		}
	}

	.drawing-details {
		max-width: 100%;
		margin: 20px auto;
		padding: 0 60px;

		.ant-collapse-content>.ant-collapse-content-box {
			padding: 15px 0;
		}
	}

	.ant-card-cover {
		padding: 10px;
	}

	.drawing-dates {
		padding: 0;
	}

	.drawing-dates li span+span {
		margin-left: 5px;
	}

	.drawing-dates li+li {
		margin-left: 15px;
	}

	.drawing-dates ul {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	.ant-card-meta-description {
		font-size: 15px;
	}

	.drawing-dates li {
		display: inline-block;
		font-size: 13px;
		color: #909090;
	}

	.ant-table-thead>tr>th {
		background-color: #ececec;
		color: black;
	}

	.ant-table-tbody>tr>td {
		border-bottom: 5px solid #f0f0f0;
	}

	.ant-collapse>.ant-collapse-item>.ant-collapse-header {
		align-items: center;
		padding: 12px 0;

		&::after {
			display: inline-block;
			content: '';
			flex-grow: 1;
			border-bottom: 1px solid #d3d3d3;
			margin-left: 2px;
		}
	}

	span.anticon.anticon-right.ant-collapse-arrow {
		background-color: #ececec;
		padding: 12px 0 12px 12px;
		border-radius: 15px 0 0 15px;
		margin: 0;
	}

	.last {
		color: gray;
	}

	.ant-collapse-header-text {
		background-color: #ececec;
		border-radius: 0 15px 15px 0;
		padding: 7px 14px 7px 7px;
	}

	.ant-collapse>.ant-collapse-item>.ant-collapse-header .ant-collapse-header-text {
		flex: 0 0 auto;
	}
}

div#pageSection1 {
	// width: 100% !important;
}

.flexbox-container {
	#viewer {

		// width: 100% !important;
		.pageSection {

			// width: 100% !important;
			// float: none !important;
			// margin: 0 auto !important;
			.canvas1 {
				// width: 600px !important;
			}
		}
	}

	.resize {
		display: flex;
		justify-content: center;
		width: 25px;
	}
}

.project-view {
	.project-view-details {
		padding: 25px 0;
	}

	.drawing-top {
		align-items: center;
	}

	.drawings {
		box-shadow: none;
	}

	.ant-card-bordered {
		border-radius: 10px;
	}

	// .project-details {
	//   display: flex;
	//   justify-content: space-evenly;
	//   align-items: center;
	// }

	.project-img {
		width: 100%;
		flex: 0 0 auto;

		img {
			width: 100%;
			height: 200px;
		}
	}

	.project-content {
		padding: 0 20px;

		h2 {
			white-space: nowrap;
			font-size: 18px;
			padding: 2px 0px;
		}

		.ant-badge-count {
			background-color: lightgray;
			margin: 0 5px;
		}

		.project-data-content {
			color: #909090;
		}

		h4 {
			white-space: nowrap;
			font-size: 12px;
		}

		label {
			font-size: 13px;
		}
	}

	.project-dates {
		align-items: center;
		justify-content: space-between;

		.ex-date {
			margin-right: 10px;
			border-radius: 5px;
		}

		div {
			background-color: #f6f6f6;
			padding: 6px 12px;
		}
	}

	.project-tool-heading {
		color: #909090;
		font-family: Inter;
		font-size: 16px;
		font-weight: 700;
		line-height: 24px;
		letter-spacing: -0.011em;
	}

	.project-tool {
		margin-top: 10px;

		p {
			margin: 0;
			padding: 0;
			color: #fc8543;
		}

		.ant-card-bordered {
			border-radius: 10px;
		}

		span {
			font-size: 12px;
		}

		.ant-card-body {
			padding: 15px;
			text-align: center;
		}
	}

	.recent-items {
		h3 {
			color: #909090;
		}

		background-color: #f6f6f6;
		padding: 35px 0;

		.ant-table-thead>tr>th {
			font-weight: 600;
			background: #ececec;
		}
	}

	.tb-name {
		margin: 0 5px;
	}

	.weather-details {
		text-align: center;

		.weather-section {
			background: white;
			margin-left: 15px;
			padding: 30px;
			border-radius: 10px;
		}

		.weather-date,
		.weather-heat,
		.rain-weather {
			justify-content: center;
			margin: 15px 0;

			.weather-data {
				background: #f6f6f6;
				padding: 5px 10px;
				color: #909090;
				border-radius: 20px;

				.ant-row {
					.weather-icons {
						margin: 0 5px;
					}
				}
			}

			span+span {
				margin: 0 10px;
			}
		}
	}
}

.login {
	img {
		max-width: 100%;
	}

	.ant-form-item-label>label {
		color: lightgray;
	}

	.login-content {
		display: block;
		margin-top: auto;
		margin-bottom: auto;
		padding: 22px;
	}

	.group-img {
		width: 70%;
		display: block;
		margin-left: auto;
		margin-right: auto;
	}

	.login-form-button,
	.proceed-btn {
		width: 100%;
		background-color: #fc8543;
		border-radius: 4px;
		border-color: white;
	}

	h2 {
		font-weight: 700;
		font-size: 20px;
		line-height: 48px;
		text-align: center;
	}

	.login-banner {
		height: 100vh;
		width: 100%;
		object-fit: cover;
		object-position: bottom;
	}
}

.planmodal {
	.ant-modal-content {
		width: 363px !important;

		.ant-modal-body {
			padding: 10px 16px;

			.modal-top {
				background: lavender;
				padding: 7px;
				border-radius: 5px;
				margin: 10px 0;

				.modal-top-img {
					margin-right: 5px;
				}
			}

			.link-sample-img {
				margin-bottom: 10px;
				width: 330px;
			}

			.modal-btn {
				justify-content: center;

				.ant-btn-primary {
					border-color: #fc8543;
					background: #fc8543;
					width: 225px;
					height: 40px;
				}
			}
		}
	}
}

.linkModal {
	width: 360px !important;
}

.HomePage {
	.navbar {
		padding: 15px;

		.ant-row {
			justify-content: space-around;
			align-items: center;

			.menu-items {
				ul {
					display: flex;

					.ant-btn {
						border: none;
						padding: 0;
					}
				}
			}

			.login-btn {
				padding: 0 20px;
				border-radius: 5px;
			}
		}
	}

	.HomeBanner {
		.banner-section {
			position: relative;

			.hbanner {
				width: 100%;
			}

			.banner-content {
				position: absolute;
				top: 28%;
				width: 520px;
				right: 3%;

				h1 {
					font-size: 36px;
					color: white;
					font-weight: 700;
					line-height: 45px;
				}

				p {
					font-size: 19px;
					color: white;
				}

				.ant-btn {
					border: none;
					font-weight: 600;
				}

				.ant-btn+.ant-btn {
					margin: 0 15px;
				}
			}
		}
	}

	.industrySection {
		.descriptions {
			text-align: center;
			padding-top: 40px;

			.leader {
				font-size: 23px;
				color: darkorange;
				font-weight: 700;
			}

			.place {
				font-size: 35px;
				font-weight: 700;
			}

			.device {
				font-size: 18px;
				font-weight: 500;
			}
		}

		.Bbanner-img {
			display: flex;
			justify-content: center;
		}
	}
}

.stage {
	padding: 0 8px;
	background: #e5dcdc;
	border-radius: 5px;
	text-align: center;
	border-radius: 32px;
	margin: 5px 0;
	font-size: 11px;
	display: inline-block;
}

.flexbox-container {
	.resize {
		display: flex;
		align-items: center;
		position: relative;

		.resize-bar {
			width: 5px;
			height: 300px;
			background-color: gray;
			border-radius: 10px;
			position: absolute;
			right: 12px;
			z-index: 0;
		}

		.resize-button {
			position: relative;
			z-index: 1;
		}
	}
}

.attachModalPopup {
	text-align: center;

	.ant-modal-content {
		width: 250px !important;
		border-radius: 10px;

		.cloud-img {
			width: 100px;
		}

		p {
			padding-top: 10px;
		}

		.download-button-img {
			width: 14px;
			margin: 0 5px;
		}

		.popupDownload {
			border-radius: 32px;
			border: 1px solid black;
		}
	}
}

.RFI-list {
	.RFI-create-section {
		background-color: white;
		padding: 10px;
		border-bottom: 1px solid lightgray;

		h3 {
			font-weight: 500;
			font-size: 20px;
			line-height: 32px;
		}

		.create-btn {
			background: #fc8543;
			border-radius: 4px;
			color: white;
		}

		.ant-dropdown-trigger {
			margin-right: 10px;
		}
	}
}

.RFI-list-data {
	.RFI-list-table {
		.RFI-filter {
			background: #ffffff;
			border-radius: 4px;
			padding: 0 8px;
			width: 153px;

			.ant-btn-block {
				width: auto;
				color: #fc8543;
				text-decoration: underline;
			}
		}
	}

	.ant-table-content {
		margin-top: 10px;
	}

	thead {
		background: #ececec;
	}

	.ant-btn-primary {
		background: #fc8543;
		border-radius: 4px;
		border-color: #fc8543;
	}

	.ant-table-tbody>tr>td {
		padding: 8px 8px;
	}

	.ant-table-thead>tr>th {
		color: #080808;
	}

	.expandRow {
		padding: 15px 0;

		h4 {
			font-weight: 700;
			font-size: 14px;
			line-height: 18px;
			color: #080808;
		}

		table,
		th,
		td {
			border: 1px solid #ececec;
			border-collapse: collapse;
			padding: 7px 33px;
		}

		tbody>tr:nth-of-type(odd) {
			background: #f6f6f6;
		}

		tbody>tr:nth-of-type(even) {
			background: #ffffff;
		}
	}
}

.ant-btn {
	text-transform: capitalize;
}

.btn-wrap {
	button+button {
		margin-inline-start: 10px;
	}
}

.title {
	font-size: 15px;
	line-height: 22px;
	font-weight: 600;
	text-transform: capitalize;
	margin-bottom: 15px;
}

.td-email {
	text-transform: lowercase !important;
}

.tabs-content {
	.ant-tabs-tab-btn {
		font-size: 14px;
	}
}

.companyUsers {
	th.ant-table-cell {
		background: #ececec !important;
	}

	.ant-table-column-title {
		.ant-row-middle {
			color: #080808;
			font-weight: 500;
		}
	}
}

.ant-tabs-tab .ant-tabs-tab-btn,
.ant-dropdown-menu-item,
.ant-table-column-title,
.ant-table-cell {
	text-transform: capitalize;
}

.drawing-info-wrap {
	.pdf-info-wrap {
		padding: 30px;
		background-color: #fff;

		iframe {
			width: 100%;
			height: 700px;
		}
	}

	.action-wrap {
		.ant-space-compact-block {
			width: auto;
			display: inline-flex;
			vertical-align: middle;

			>.ant-btn-compact-first-item {
				display: none;
			}
		}

		.ant-btn {
			font-size: 10px;
			line-height: 17px;
			height: auto;
			padding: 3px 10px;
			text-transform: uppercase;
			background-color: #ececec;

			&.ant-btn-dangerous {
				background-color: #fbe1e1;
			}

			&.ant-btn-default.ant-dropdown-trigger {
				border-radius: 50%;
				height: 30px;
				width: 30px;
				padding: 0;
			}
		}

		.ant-btn+.ant-btn {
			margin-left: 7px;
		}
	}

	.project-list-table {
		padding: 30px;
		margin-top: 2px;
		background-color: #fff;
	}

	.pageContainer {
		border: none;
		box-shadow: none;
	}

	#scroll-view {
		background-color: #fff;
	}

	#viewer {
		min-width: 600px;
		min-height: 600px;
	}

	&.project-list .project-list-data .ant-tabs-nav .ant-tabs-nav-wrap {
		padding: 0 5%;
	}
}

.general-info {
	background-color: #fff;
	padding: 24px;

	.title {
		margin-bottom: 25px;
	}

	label {
		display: block;
		text-transform: capitalize;
		font-size: 12px;
		line-height: 18px;
		font-weight: 500;
		margin-bottom: 4px;
		color: #909090;
	}

	.general-value {
		color: #080808;
		display: block;
		font-size: 14px;
		line-height: 18px;
		font-weight: 600;
		padding: 10px 12px;
		background: #f6f6f6;
		border-radius: 4px;
		text-transform: capitalize;
	}
}

.drawings .ant-radio-button-wrapper {
	border: 1px solid #d9d9d9;
	box-shadow: none;
}

.drawings .ant-radio-button-wrapper-checked,
.drawings .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover,
.drawings .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled),
.drawings .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active {
	border: 1px solid #fc8543;
	box-shadow: none;
}

.drawings .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
	display: none;
}

.drawings .ant-radio-button-wrapper-checked svg path {
	stroke: white;
}

.drawings .ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child {
	border: 1px solid #fc8543;
}

.ant-dropdown-trigger {
	cursor: pointer;
}

.alerts>img {
	cursor: pointer;
}

.popover-icons svg {
	border-radius: 0 !important;
	cursor: pointer;

	&:hover {
		background-color: #fc8543 !important;
	}
}

.priority-list>div {
	&:hover {
		cursor: pointer;
	}
}

.footer-button .ant-radio-button-wrapper {
	&.ant-radio-button-wrapper-disabled {
		background-color: #ccc;

		svg {
			background-color: #ccc !important;
		}
	}
}

.ant-tabs {
	.ant-badge {
		margin-left: 5px;
	}
}

.ant-notification-notice {
	&.ant-notification-notice {
		display: flex;
		align-items: center;
		padding: 15px;

		.ant-notification-notice-content {
			flex-grow: 1;
		}

		&.success {
			color: #107023;

			.ant-notification-notice-message {
				color: #107023;
			}
		}

		&.error {
			color: #d10c1d;

			.ant-notification-notice-message {
				color: #d10c1d;
			}
		}

		.ant-notification-notice-with-icon {
			display: flex;
			align-items: center;
		}

		.ant-notification-notice-message {
			margin: 0;
			padding: 0 8px;
			font-size: 14px;
		}

		.ant-notification-notice-close {
			position: static;
		}

		.ant-notification-notice-icon {
			margin: 0;
			position: static;
			display: flex;

			.icon {
				vertical-align: top;
				font-size: 30px;
				margin-right: 10px;
			}
		}
	}
}

.more-dropdown-wrap.ant-dropdown {
	.ant-dropdown-menu-item {
		padding: 0;
	}

	.ant-dropdown-menu-title-content>div {
		padding: 5px 12px;
	}
}

.punchList.more-dropdown-wrap.ant-dropdown {
	.status {
		padding: 3px 0 !important;
	}
}

.text-danger {
	color: #f00;
}

.ant-select-selection-item,
.ant-select-item-option-content {
	text-transform: capitalize;
}

.loader {
	position: relative;
	overflow: hidden;
}

.loader::before,
.loader::after {
	content: '';
	display: inline-block;
	position: absolute;
}

.loader::after {
	background-color: #fff;
	opacity: 1;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
}

.loader:before {
	top: 50%;
	left: 50%;
	margin: -30px;
	border: 8px solid #cdcdcd;
	border-radius: 50%;
	border-top: 8px solid #fc8543;
	width: 60px;
	height: 60px;
	-webkit-animation: spin 2s linear infinite;
	/* Safari */
	animation: spin 2s linear infinite;
	z-index: 1;
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

.nav-options {
	button+button {
		margin-left: 10px;
	}
}

/*Responsive*/

@media only screen and (min-width: 1440px) {
	.auth-header {
		.safety-logo {
			width: 56px;
		}

		.plan-dropdown,
		.version-dropdown {
			.ant-select {
				font-size: 16px;
			}

			.plan-dropdown-img,
			.version-dropdown-img {
				width: 30px;
				height: 25px;
			}
		}

		.header-actions {
			.ant-btn {
				font-size: 16px;
			}

			.draw-action-btn,
			.preview-action-btn {
				width: 25px;
				height: 25px;
			}
		}

		.publish-option,
		.more-option {
			width: 35px;
			height: 35px;
		}
	}

	.tab-list {
		.left-tab-image {
			width: 30px;
		}

		.d-flex {
			font-size: 16px;
		}
	}

	.drawing {
		.ant-breadcrumb-link {
			.project {
				font-size: 25px;
			}
		}
	}

	.project-view {
		.project-view-details {
			.project-tool-heading {
				font-size: 20px;
			}

			.project-tool {
				.ant-card-body {
					p {
						font-size: 16px;
					}

					span {
						font-size: 14px;
					}
				}
			}

			.project-content {
				h2 {
					font-size: 24px;
				}

				.project-data-content {
					font-size: 16px;

					.stage {
						font-size: 14px;
					}
				}

				.project-dates {
					.ex-date {
						h4 {
							font-size: 14px;
						}
					}
				}
			}
		}

		.recent-items {
			h3 {
				font-size: 16px;
			}

			.ant-table-thead {
				tr {
					font-size: 14px;
				}
			}

			.weather-details {

				.weather-date,
				.weather-data,
				.rain-weather {
					font-size: 14px;
				}

				.forecast {
					font-size: 25px;
				}
			}
		}
	}

	.weather-section {
		.forecast {
			a {
				font-size: 14px;
			}
		}
	}
}

@media only screen and (max-width: 1024px) {
	.tab-list {
		width: 320px !important;
		height: 300px;
		overflow: auto;
	}

	.drawings {
		.project {
			color: grey;
			font-size: 18px;
		}
	}

	.project-view-details {
		.project-details {
			.ant-row {
				justify-content: center;
			}
		}

		.project-tools-section {
			padding-top: 30px;

			.project-tool-heading {
				font-size: 20px;
			}
		}
	}

	.drawing-list {
		.drawings {
			.ant-breadcrumb-link {
				font-size: 17px;
			}
		}
	}

	.footer-button {
		.ant-radio-button-wrapper svg {
			margin-top: 1px;
			width: 20px;
		}
	}
}

@media only screen and (max-width: 992px) {
	.auth-header {
		.safety-logo {
			width: 30px;
		}

		.plan-dropdown,
		.version-dropdown {
			margin-left: 10px;

			.ant-select-selector {
				padding: 0 5px;
			}
		}

		.ant-select {
			font-size: 12px;
		}

		.ant-btn {
			font-size: 12px;
		}

		.publish-option,
		.more-option {
			width: 30px;
		}

		.header-actions {

			.draw-action-btn,
			.preview-action-btn {
				width: 18px;
			}
		}
	}

	.flexbox-container {
		.tab-list {
			.ant-tabs-left {
				.ant-tabs-nav {
					margin: 0;
				}

				.ant-tabs-tab {
					padding: 5px 7px;
					margin: 0;

					.left-tab-image {
						width: 20px;
					}
				}
			}
		}

		.resize {
			.resize-bar {
				height: 250px;
				right: 10px;
			}
		}
	}

	.tab-list {
		.allTab {
			.addedMarkup {
				.d-flex {
					h3 {
						font-size: 13px;
					}

					p {
						font-size: 11px;
					}
				}
			}

			.issuesField {
				.markup-issues {
					.d-flex {
						.markupIssue-img {
							width: 25px;
						}

						.issues-details {
							h3 {
								font-size: 12px;
							}
						}
					}
				}
			}
		}
	}

	.drawing-list {
		.drawings {
			.ant-breadcrumb-link {
				font-size: 15px;
			}
		}
	}
}

.site-layout-content {
	background: #ffffff;
	padding: 8px 0 15px 24px;

	.btn-grey {
		background: #909090 !important;
		color: #ffffff !important;

		&:hover,
		&:active,
		&:focus {
			border-color: #909090 !important;
		}
	}
}

.ant-layout-content {
	padding: 0 60px;
}

.header-title {
	position: relative;
	font-weight: 700;
	font-size: 32px;
	line-height: 48px;
	color: #080808;
	margin-bottom: 0 !important;
	display: flex;
    align-items: center;

	.header-grey {
		color: #909090;
	}

	.lock-badge {
		padding: 5px 15px;
		background: #e5f8e9;
		border: 1px solid #009e1f;
		border-radius: 32px;
		font-weight: 400;
		font-size: 12px;
		position: relative;
		top: -5px;
		margin: 0 5px;

		.anticon {
			color: #009e1f;
		}
	}
}

.warning {
	background: #fff8ea;
	border: 1px solid #d69b25;
}

.g-icon-lock {
	width: 24px;
	margin-top: 0;
}

.mb-5 {
	margin-bottom: 5px !important;
}

.table-row-light {
	background-color: #f6f6f6 !important;
}

.table-row-dark {
	background-color: #ffffff !important;
}

.rfi-general {
	.ant-collapse {
		border: none !important;

		>.ant-collapse-item {
			border-bottom: none !important;
		}
	}

	.ant-collapse-header {
		color: #909090 !important;
	}

	.ant-collapse-content {
		border-top: none !important;
	}

	.round-border {
		border: 1px solid #909090;
		border-radius: 10px;
		padding: 2px;
	}

	table>tbody>tr {
		>td:nth-of-type(1) {
			border-right: none !important;
		}

		>td {
			padding: 10px 12px !important;
		}
	}

	.small-badge {
		background: #ececec;
		border-radius: 32px;
		margin: 3px 2px 1px 0;
		padding: 3px 8px;
		display: inline-block;
		text-align: center;
	}
}

.btn-icon {
	width: 16px;
}

.mb-0 {
	margin-bottom: 0 !important;
}

.d-flex {
	display: flex !important;
}

.flex-column {
	-webkit-box-orient: vertical !important;
	-webkit-box-direction: normal !important;
	-ms-flex-direction: column !important;
	flex-direction: column !important;
}

.wrapper-class .wrapperClassName {
	padding: 1rem;
	border: 1px solid #d3d3d3;
}

.editor-class,
.editorClassName {
	padding: 0.5rem !important;
	border: 1px solid #d3d3d3 !important;
	background: #FFFFFF;
	min-height: 150px;
}

.toolbar-class,
.toolbarClassName {
	border: 1px solid #d3d3d3;
}

.questionSection {
	.card {
		border-radius: 10px;
		box-sizing: border-box;

		.reviews-list {
			flex-direction: column;
			justify-content: center;
			align-items: center;

			.official-res {
				position: absolute;
				right: 78px;

				span {
					color: #080808 !important;
					font-size: 12px !important;
					line-height: 18px;
					font-weight: 400 !important;
				}
			}

			.actions {
				position: absolute;
				right: 20px;
				height: 56px;
				margin-left: auto;
				padding: 0 5px;
			}

			.review-card {
				display: flex;
				flex-direction: column;
				justify-content: space-around;
				border-radius: 4px;
				margin: 10px 0;
				border: 1px solid #ececec;

				&:first-child {
					margin-top: 0;
				}

				.attach-title {
					font-weight: 500;
					font-size: 14px;
					line-height: 18px;
					color: #909090;

					img {
						width: 13px;
						margin-right: 5px;
					}
				}

				.site-card-wrapper {
					border-top: 1px solid #ececec;
					padding: 10px;
				}

				.ant-card-meta-title {
					overflow: inherit !important;
					white-space: inherit !important;
					text-overflow: inherit !important;
				}

				.ant-card-body {
					padding: 10px !important;
				}

				.attachment-title {
					font-weight: 500;
					font-size: 12px;
					line-height: 16px;
					letter-spacing: 0.0025em;
					color: #080808;
				}

				.review-header {
					display: flex;
					align-items: flex-start;
					justify-content: flex-start;
					color: #000000;
					font-weight: bold;
					background: #f6f6f6;

					.question-tag {
						width: 56px;
						height: 56px;
						background: #ececec;
						text-align: center;
						padding: 10px 5px;

						.name {
							letter-spacing: 0.0025em;
							font-weight: 500;
							font-size: 10px;
							line-height: 16px;
							color: #909090;
						}
					}

					.name-group {
						display: flex;
						align-items: center;
						height: 56px;
						padding: 10px;

						.initials {
							display: flex;
							justify-content: center;
							align-items: center;
							margin-right: 12px;
							border-radius: 50%;
							background: #d56a6a;

							img {
								width: 35px;
								border-radius: 5px;
							}
						}

						.name {
							font-weight: 500;
							font-size: 16px;
							line-height: 24px;
							color: #080808;

							span {
								font-weight: 400;
								font-size: 14px;
								line-height: 18px;
								color: #909090;
							}
						}

						.date {
							font-weight: 400;
							font-size: 12px;
							line-height: 16px;
							color: #909090;
							letter-spacing: 0.0025em;
						}
					}
				}

				.review-description {
					color: #000000;
					font-weight: 400;
					padding: 9px 8px;
					margin: 10px;
					max-width: 100%; /* Adjust as needed */
					/* Enable text truncation with ellipsis */
					word-wrap: break-word;
  					overflow-wrap: break-word;
				}

				.review-details {
					display: flex;
					justify-content: space-between;
					margin: 0px 45px;
					align-items: center;
					color: #8c8aa7;

					.share-group {
						display: flex;
						padding: 8px;
						border: 1px solid #1f1e2d;
						border-radius: 5px;
						cursor: pointer;

						i {
							margin-right: 10px;
						}
					}
				}
			}
		}
	}
}

.numberCircle {
	display: inline-block;
	border-radius: 50%;
	border: 1px solid #ececec;
	font-size: 20px;
	background: #ececec;
	color: #909090;
	width: 40px;
	text-align: center;

	&:before {
		content: '\200B';
		display: inline-block;
		line-height: 0px;
		padding-top: 50%;
		padding-bottom: 50%;
		padding-left: 8px;
	}

	&:after {
		content: '\200B';
		display: inline-block;
		line-height: 0px;
		padding-top: 50%;
		padding-bottom: 50%;
		padding-right: 8px;
	}
}

.ant-list-item.selected {
	background: #fff6f0;
}

.d-none {
	display: none;
}

.tooltipBox {
	width: 310px;
	height: 250px;
	overflow-y: auto;
	scrollbar-width: none;
	background-color: #ffffff;
	color: #080808;
	padding: 16px 16px 0 16px;
	position: absolute;
	border-radius: 8px;
	z-index: 9999;
	text-align: left;
	box-shadow: rgba(100, 100, 111, 0.2) 0 7px 29px 0;
	left: -40px;
	top: 40px;

	.bottom-btn {
		//position: fixed;
		position: sticky;
		bottom: 0;
		background: #ffffff;
		width: 100%;
		padding: 10px 0;
		text-align: right;

		.cancel {
			border: 1px solid #f6f6f6;
			background: #ffffff;
			color: #909090;
		}
	}
}

.tooltipBox::-webkit-scrollbar {
	display: none;
}

.toolTipList {
	padding: 10px 15px;
	color: #080808;

	&:hover {
		background: #fff6f0;
	}
}

.tooltipBox.arrow-top {
	//margin-top: 40px;
}

.tooltipBox.arrow-top:after {
	content: ' ';
	position: absolute;
	right: 30px;
	top: -15px;
	border-top: none;
	border-right: 15px solid transparent;
	border-left: 15px solid transparent;
	border-bottom: 15px solid rgba(100, 100, 111, 0.2);
}

.text-right {
	text-align: right;
}

.header-btn {
	padding: 10px 60px;
}

.white-grey {
	color: #909090 !important;
	background: #ffffff !important;
	border: 1px solid #909090 !important;
}

.rfi-view-screen {
	.ant-layout .ant-tabs-nav {
		margin-bottom: 0;
	}

	.ant-tabs-content-holder {
		background: #e2e2e2 !important;

		.ant-tabs-content {
			margin: 15px 0;
		}
	}
}

.flex-end {
	justify-content: flex-end;
}

.flex-row-reverse {
	-webkit-box-orient: horizontal !important;
	-webkit-box-direction: reverse !important;
	-ms-flex-direction: row-reverse !important;
	flex-direction: row-reverse !important;
}

.small-icon {
	width: 16px;
}

.relative {
	position: relative;
}

.mr-10 {
	margin-right: 10px;
}

.pl-10 {
	padding-left: 10px;
}

.mb-10 {
	margin-bottom: 10px;
}

.maroon {
	color: #ad2662;
}

.toast-class {
	border: 1px solid #f4f4f4;
	left: 80px;
	border-radius: 8px;
	filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.3));
}

.success {
	background: #e5f8e9 !important;
}

.toast-class.warning {
	background: #fff8ea !important;
}

.info {
	background: #ebf3ff;
}

.error {
	background: #ffeaea;
}

.p-2 {
	padding: 2px !important;
}

.border-bottom-none {
	border-bottom: none !important;
	border-left: none !important;
	border-right: none !important;
}

.flex-row-reverse {
	flex-direction: row-reverse !important;
}

.flex-column {
	-webkit-box-orient: vertical !important;
	-webkit-box-direction: normal !important;
	-ms-flex-direction: column !important;
	flex-direction: column !important;
}

.create-project {
	padding: 20px 60px;

	h3 {
		font-weight: 700;
		font-size: 20px;
		line-height: 32px;
	}

	.drag-and-drop {
		padding: 17px 20px;
	}

	label {
		color: #909090;
	}

	ul {
		list-style-type: none;
		padding: 0;
		border: 1px solid #f6f6f6;
		border-radius: 4px;

		li {
			padding: 7px 16px 7px 15px;
		}

		li:nth-child(odd) {
			background-color: #f6f6f6;
		}

		li:nth-child(even) {
			background-color: #ffffff;
		}

		.ant-form-item {
			margin-bottom: 0;

			.ant-form-vertical,
			//.ant-form-item-row {
			//	flex-flow: nowrap;
			//	flex-direction: row;
			//	align-items: center;
			//}

			.ant-form-vertical,
			.ant-form-item-label,
			.ant-col-24.ant-form-item-label,
			.ant-col-xl-24.ant-form-item-label {
				width: 50%;
			}
		}
	}

	h4,
	.ant-collapse-header-text {
		font-weight: 700;
		font-size: 16px;
		line-height: 24px;
	}

	.toggle {
		border: 1px solid #ececec;
		padding: 12px;
		margin: 15px 0;
	}

	.ant-switch {
		margin-right: 5px;
	}

	.ant-switch-checked {
		background-color: #fc8543;
	}

	.ant-collapse>.ant-collapse-item>.ant-collapse-header {
		padding: 10px 0;
	}

	.ant-collapse-ghost>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
		padding-top: 0;
	}

	.ant-collapse-content>.ant-collapse-content-box {
		padding: 0;
	}

	.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
		display: inline-block;
		margin-right: 4px;
		color: #ff4d4f;
		font-size: 14px;
		font-family: SimSun, sans-serif;
		line-height: 1;
		content: '*';
	}

	.ant-form-item-label>label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
		display: none;
	}

	.project-information {
		.upload+.upload {
			margin: 20px 0;
		}

		.ant-form-item {
			margin-bottom: 15px;
		}
	}

	.project-details {
		.ant-form-item-label>label .ant-form-item-tooltip {
			order: 3;
		}
	}
}

.drag-and-drop {
	border: 1px solid #ECECEC !important;
	background: #ffffff;
	text-align: center;
	padding: 24px 16px;

	.upload-icon {
		font-size: 20px;
		margin-left: 34px;
	}

}
.drag-and-drop-btn {
	background: #f6f6f6;
	border: none;
	margin-right: 12px;
	width: 100%;
	color: #909090;
	&:hover, &:focus, &:active {
		background: #f6f6f6;
		color: #909090;
	}
}
.upload-btn {
	background: #ffebe0;
	color: #fc8543;
	border: none;
	width: 100%;
    margin-left: 10px;
	height: 42px;
	justify-content: center;
	&:hover, &:focus, &:active {
		background: #ffebe0;
		color: #fc8543;
		border: none;
	}
}

.ant-select-item-option-content .option-row,
.ant-select-single .ant-select-selector .ant-select-selection-item .option-row {
	display: flex;
	align-items: center;
	line-height: 1;

	.img-wrap {
		width: 30px;
		margin-right: 8px;

		img {
			width: 100%;
		}
	}

	.info-wrap {
		h4 {
			color: #080808;
			font-weight: 400;
			font-size: 14px;
			line-height: 18px;
			margin: 0;
		}

		span {
			font-size: 10px;
			line-height: 12px;
			color: #909090;
		}
	}
}

.custom-field-dropdown {
	.ant-select-item-option {
		flex-direction: row-reverse;
		align-items: center;
	}

	&.ant-select-dropdown .ant-select-item-option-state {
		display: inline-block;
		margin-right: 5px;
	}

	.ant-tag {
		margin: 0 0 0 8px;
	}

	.ant-select-item-option-content {
		display: flex;
		align-items: center;
	}

	.custom-selected-view {
		display: none;
	}
}

.custom-field-select {
	.option-label {
		width: 40%;
		font-size: 12px;
	}

	.custom-selected-view {
		width: 60%;
		padding: 0 15px;
	}

	.ant-select-selection-item-content {
		flex-grow: 1;
	}

	.ant-tag {
		margin: 0 5px;
	}

	.ant-select-selection-item {
		height: auto;
	}

	.ant-select-selection-item-content {
		display: flex;
		align-items: center;
	}

	.custom-list-view {
		display: none;
	}
}

.create-submittal-wrap {

	.ant-input,
	.ant-select-selection-search-input,
	.ant-select-selection-item,
	.ant-picker-input input {
		font-size: 14px;
	}

	.ant-select-single .ant-select-selector .ant-select-selection-item::after {
		display: none;
	}

	.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
		height: auto;
		padding: 4px 11px;
	}

	.ant-form-item-label {
		padding-bottom: 4px;

		label {
			color: #909090;
			font-weight: 500;
			font-size: 12px;
			line-height: 16px;
		}
	}

	.ant-form-item-required {
		flex-direction: row-reverse;

		&::before {
			margin: 0 0 0 4px;
		}
	}

	.breadcrumb-wrap {
		background: #f6f6f6;

		.ant-page-header {
			background-color: transparent;
			padding: 15px 0;
		}
	}

	.ant-page-header-heading {
		align-items: center;
	}

	.ant-page-header-back {
		padding: 7px 12px;
		background: #fff;
		border-radius: 4px;
	}

	.ant-page-header-heading-title {
		.title-row {
			img {
				width: 24px;
				margin-right: 7px;
			}

			h2 {
				font-weight: 400;
				font-size: 20px;
				line-height: 32px;
				color: #080808;
				margin: 0;
			}
		}
	}
}

.ant-picker {
	width: 100%;
}

.sub-dist-dropdown {
	.ant-select-multiple .ant-select-selection-item {
		height: auto;

		.option-row {
			display: flex;
			align-items: center;
		}
	}
}

.check-panel {
	background: #f6f6f6;
	border: 1px solid #ececec;
	border-radius: 4px;

	&.ant-collapse>.ant-collapse-item>.ant-collapse-header {
		display: flex;
		align-items: center;
	}

	.ant-collapse-header-text {
		h3 {
			margin: 0;
			color: #080808;
			font-weight: 500;
			font-size: 14px;
			line-height: 18px;
		}

		span {
			color: #909090;
			font-weight: 400;
			font-size: 12px;
			line-height: 16px;

			&.info-tooltip {
				color: #fc8543;
				font-size: 20px;
				margin-left: 10px;
			}
		}
	}

	.ant-collapse-expand-icon {
		svg {
			fill: #fc8543;
			width: auto;
			height: auto;
			font-size: 20px;
		}
	}
}
.distribution-panel {
	&.ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
		min-height: 70px;
	}
}
.view-panel {
	&.ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
		min-height: 70px !important;
	}
	 .distribution-selected {
		align-items: center;
		width: 100%;
		justify-content: center;
	}
}
.IndividualsSection {
	.ant-collapse-header-text {
		color: rgba(0, 0, 0, 0.85) !important;
	}
	.table-row-light {
		// margin-bottom: 5px !important;
	}
}
.rfi-assignee {
	.distribution-panel {
		&.ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
			min-height: fit-content;
		}
	}
	.arrow-panel {
		&.ant-collapse>.ant-collapse-item>.ant-collapse-header {
			padding: 5px 0;
			background: #f6f6f6 !important;
			.ant-collapse-header-text {
				color: rgba(0, 0, 0, 0.85) !important;
			}
		}
	
		&.ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
			padding: 0px !important;
			background: #f6f6f6;			
			border: none;
			border-radius: 4px;
		}
	}
}
.arrow-panel {
	&.ant-collapse>.ant-collapse-item>.ant-collapse-header {
		padding: 5px 0;
	}

	&.ant-collapse-borderless>.ant-collapse-item>.ant-collapse-content>.ant-collapse-content-box {
		// padding: 10px 16px 16px !important;
		background: #f6f6f6;
		
		// border: 1px solid #ececec;
		border-radius: 4px;
	}

	.ant-form-item {
		margin: 0;

		+.ant-form-item {
			margin-top: 12px;
		}
	}

	.ant-collapse-expand-icon {
		svg {
			border: 1px solid #909090;
			fill: #909090;
			padding: 4px;
			width: auto;
			height: auto;
			border-radius: 50%;
		}
	}

	&.ant-collapse-borderless {
		.ant-collapse-header {
			background-color: #fff;
		}
	}
}

.ant-form-item-label {
	label {
		text-transform: capitalize;
	}
}

.submittal-form-wrap {
	padding: 40px 0;
	background: #fff;

	.ant-collapse {
		+.ant-collapse {
			margin-top: 15px;
		}
	}

	.ant-input,
	.ant-picker {
		padding: 8px 11px;
	}

	textarea {
		height: 130px;
	}

	.option-label {
		width: 40%;
	}

	.custom-selected-view {
		margin: 0 10px;
		flex-grow: 1;
	}

	.selected-row {
		+.selected-row {
			margin-top: 15px;
		}

		.option-label {
			color: #080808;
			font-weight: 500;
			font-size: 14px;
			line-height: 18px;
		}
	}
}

.check-panel {
	.ant-form-item {
		margin-bottom: 24px;
	}

	.selected-row {
		align-items: center;

		+.selected-row {
			margin-top: 15px;
		}

		.option-label {
			color: #080808;
			font-weight: 500;
			font-size: 14px;
			line-height: 18px;
			width: 40%;
		}

		.custom-selected-view {
			margin: 0 10px;
			flex-grow: 1;
		}
	}
}

ul {
	padding: 0;
	margin: 0;
}

.flex-wrap {
	flex-wrap: wrap;
}

.justify-space-between {
	justify-content: space-between !important;
}

.distribution-panel {
	.distribution-selected {
		margin-top: 5px;

		.selected-row {
			&:nth-child(n + 3) {
				margin-top: 12px;
			}
		}
	}

	.ant-form-item.sub-dist-dropdown {
		margin-bottom: 12px;
	}

	.ant-select {
		.option-row {
			.img-wrap {
				display: none;
			}

			.info-wrap {
				h4 {
					margin: 0;
					font-weight: 400;
					font-size: 12px;
				}

				span {
					display: none;
				}
			}
		}
	}
}

.ant-form-item-explain-error {
	font-size: 10px;
}

.selected-row {
	.anticon {
		color: #fc8543;
		font-size: 20px;
		margin-left: auto;
	}
}
.collapased-error {
	.questions {
		border: 1px solid #ff4d4f !important;
	}
}
.question_validator {
	.ant-form-item-row {
		position: relative;
		top: -30px;
	  }
}
.distribution-selected {
	.selected-row {
		width: calc(50% - 7.5px);
		border-radius: 4px;
		padding: 8px 10px;
		background: #fff;
		flex: 0 0 auto;
		height: 50px;

		+.selected-row {
			margin: 0;
		}

		&:nth-child(n + 3) {
			margin-top: 15px;
		}
	}

	.option-row {
		display: flex;
		align-items: center;
		line-height: 1;
		flex-grow: 1;
	}

	.selected-row+.selected-row {
		margin: 0;
	}

	.info-wrap {
		h4 {
			color: #080808;
			margin: 0;
			font-weight: 400;
			font-size: 13px;
			line-height: 20px;
			text-transform: capitalize;
			display: flex;
		}

		span {
			color: #909090;
			font-weight: 400;
			font-size: 10px;
			line-height: 12px;
		}
	}

	.img-wrap {
		width: 30px;
		margin-right: 10px;

		img {
			width: 100%;
		}
	}
}

.ant-form-item {
	+.check-item {
		margin-top: 15px;
	}
}

.check-item {
	.ant-checkbox-inner {
		border-radius: 0;
		width: 20px;
		height: 20px;

		&::after {
			width: 7px;
			height: 12px;
			top: 8px;
			left: 4px;
		}
	}

	.ant-checkbox-wrapper {
		align-items: center;
	}

	.ant-checkbox {
		top: 0;

		+span {
			padding-left: 12px;

			h3 {
				color: #080808;
				font-weight: 500;
				font-size: 14px;
				line-height: 18px;
				margin: 0;
			}

			span {
				font-weight: 400;
				font-size: 12px;
				line-height: 16px;
				color: #909090;
			}
		}
	}
}

.info-field {
	align-items: center;

	>div {
		flex-grow: 1;
	}

	.info-tooltip {
		color: #fc8543;
		font-size: 20px;
		margin-left: 10px;
	}
}

textarea,
textarea.ant-input {
	resize: none;
}

.create-project {
	.ant-collapse-item {
		.ant-collapse-expand-icon {
			svg {
				fill: #fc8543;
				font-size: 22px;
			}
		}
	}

	.ant-form-item-label label {
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		color: #080808;
	}

	.ant-form-item-explain-error {
		font-size: 10px;
		margin: 3px 0 0;
		font-weight: 500;
	}

	.ant-collapse-header {
		align-items: center;
	}

	.anticon.ant-collapse-arrow {
		font-size: 22px;
		margin-right: 10px;
		vertical-align: top;
	}

	.ant-input {
		padding: 8px;
		border: 1px solid #d3d3d3;
		border-radius: 4px;
		color: #909090;
	}
}

.ant-breadcrumb {
	text-transform: capitalize;

	li {
		display: flex;
	}

	.bc-nav-link {
		margin-right: 5px;
		cursor: pointer;
	}

	.ant-breadcrumb-link {
		display: inline-flex;
		align-items: center;
	}
}

.ant-dropdown {
	&.apps-menu-wrap {
		.ant-dropdown-menu-item:hover {
			background: #fff;
		}
	}
}

.ant-table {
	.ant-table-column-sorter {
		display: none;
	}
}

.filter-column-header {
	.text {
		white-space: nowrap;
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 4px;

		svg {
			margin-left: 10px;
		}
	}
}

.submittal-view-wrap {
	background: #f6f6f6;

	.ant-page-header-back-button span {
		background: #fff;
		padding: 10px;
	}

	.header-title {
		display: flex;
		align-items: center;

		.icon-lock {
			font-size: 32px;
			margin-left: 5px;
			color: #fc8543;
		}
	}

	.ant-tabs-nav .ant-tabs-nav-wrap {
		max-width: 100%;
		width: 100%;
		margin: 0 auto -1px;
		padding: 0 60px;
	}

	.days-left {
		padding: 0 15px;

		p {
			margin: 0;
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			color: #080808;
			width: 100%;
			float: left;
		}

		span {
			color: #909090;
			display: block;
		}
	}

	.header-info {
		display: flex;
		align-items: center;
		padding: 0 15px;

		.user-img {
			width: 35px;
			flex: 0 0 auto;
			margin-right: 12px;
		}

		.user-info {
			flex-grow: 1;

			h3 {
				margin: 0;
				font-weight: 400;
				font-size: 14px;
				line-height: 18px;
				color: #080808;
			}

			span {
				display: block;
				font-weight: 400;
				font-size: 10px;
				line-height: 12px;
				color: #909090;
			}
		}
	}

	.header-count {
		background: #f6f6f6;
		color: #909090;
		font-weight: 700;
		font-size: 16px;
		border-radius: 50%;
		width: 35px;
		height: 35px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
	}

	.arrow-panel.ant-collapse>.ant-collapse-item>.ant-collapse-header {
		align-items: center;
		display: flex;

		.anticon {
			vertical-align: top;
			margin-right: 8px;
		}
	}

	.ant-collapse-header-text {
		font-weight: 700;
		font-size: 14px;
		line-height: 18px;
		color: #909090;
	}

	.title-row {
		h2 {
			font-weight: 700;
			font-size: 32px;
			line-height: 48px;
			margin: 0;
			display: flex;
			align-items: center;
			color: #080808;

			.icon {
				color: #fc8543;
				margin-left: 5px;
				font-size: 35px;
			}
		}

		p {
			font-weight: 400;
			font-size: 14px;
			line-height: 22px;
			color: #909090;
			white-space: normal;
		}
	}

	.ant-steps-item {
		+.ant-steps-item {
			margin-top: 10px;
		}
	}

	.ant-page-header {
		background: none;
		padding: 20px 0;
	}

	.ant-steps-item-title {
		width: 100%;
		padding: 0;
	}

	.ant-collapse {
		border: none;
		background: #fff6f0;
		border: 1px solid #d3d3d3;
		border-radius: 8px;
		overflow: hidden;
	}

	.ant-collapse-content {
		border: none;
	}

	.ant-steps-item-active .ant-collapse>.ant-collapse-item .ant-collapse-header {
		border-left: 8px solid #fc8543;
		border-radius: 8px;
		border-bottom: 1px solid #d3d3d3;
		background: #fff6f0;

		.header-count {
			background: #ffebe0;
			color: #fc8543;
		}
	}

	.ant-steps-item.ant-steps-item-active .ant-collapse-content.ant-collapse-content-hidden,
	.ant-steps-item.ant-steps-item-active .ant-collapse-content.ant-collapse-content-active,
	.ant-steps-item.ant-steps-item-active .ant-collapse-content.ant-collapse-content-inactive {
		display: block;
		height: auto !important;
		opacity: 1 !important;
	}

	.ant-steps-item .ant-collapse-content.ant-collapse-content-active {
		display: none;
	}

	.ant-steps-item-active .ant-steps-item-content {
		background: #fff6f0;
	}

	.status-label {
		padding: 4px 8px;
		background: #fff8ea;
		color: #d69b25;
		font-weight: 400;
		font-size: 12px;
		line-height: 16px;
		border: 1px solid #d69b25;
		border-radius: 32px;
		text-transform: capitalize;
		margin-left: 8px;

		&.open {
			background: #ffeaea;
			border-color: #9f1853;
		}

		&.closed {
			color: #009e1f;
			border-color: #009e1f;
			background: #e5f8e9;
		}
	}
}

/*Responsive*/
// @media only screen and (max-width: 1440px) {
//   .create-project {
//     .ant-form-item-label label {
//       font-size: 12px;
//       line-height: 16px;
//     }

//     .ant-input,
//     .ant-select-selection-placeholder {
//       font-size: 12px;
//     }
//   }
// }

.justify-content-end {
	-webkit-box-pack: end !important;
	-ms-flex-pack: end !important;
	justify-content: flex-end !important;
}

.align-items-end {
	-ms-flex-align: end !important;
	align-items: flex-end !important;
}

.readyOnlyContent {
	height: 172px;
	background: #f6f6f6;
	border: 1px solid #ececec;
	border-radius: 4px;
	padding: 10px 13px;

	.dot-icon {
		width: 5px;
		height: 15px;
	}
}

.dot {
	padding: 0 5px;
	font-size: 14px;
}

.list-header {
	.ant-page-header-heading-title {
		display: flex;
		align-items: center;

		.icon {
			margin-right: 10px;
		}
	}
}

.compareRevision {
	.comparisonSelect {
		background: #f6f6f6;
		padding: 10px;

		.compareRevisionModal {
			padding: 0 5px;
			flex-grow: 1;

			label {
				color: #909090;
			}

			.compareRevisionSelect {
				width: 100%;
			}
		}
	}

	.modalPreview {
		padding: 10px 0;

		.modalPreviewContent {
			justify-content: space-between;
			align-items: center;
			padding: 10px 0;

			div+div {
				padding-left: 10px;
			}

			.Addition,
			.Removal {
				width: 15px;
				height: 15px;
				border-radius: 50%;
				margin-right: 5px;
			}
		}

		.modalPreviewImage {
			padding: 10px;
			background: #f6f6f6;

			.previewImage {
				width: 100%;
			}
		}
	}

	.compareButtonSection {
		margin-top: 7px;

		.compareButton {
			background-color: #fc8543;
			color: white;
		}
	}

	.ant-modal-title {
		font-weight: 700;
		font-size: 20px;
	}
}

.versionTableContent {
	.compareButton {
		color: #fc8543;
		border: 1px solid #fc8543;

		svg {
			margin-top: 3px;
			margin-right: 6px;
		}
	}
}

.download-btn {
	.anticon-down {
		float: right;
		margin-top: 4px;
		margin-left: 6px;
	}
}

.more-btn {
	.ant-dropdown-menu {
		right: 30px;
	}
}

.actions {
	width: 90px
}

.document {
	margin-bottom: 0;

	.title {
		color: #080808;
	}

	span {
		color: #909090;
	}
}

.btn-upload-light {
	background: #ffebe0;
	color: #fc8543;
	border: none;
	font-size: 14px;
	font-weight: 500;
	line-height: 18px; /* 128.571% */
	letter-spacing: -0.084px;
	padding: 8px 12px;
	height: 40px;
	&:hover {
		background: #ffebe0;
		color: #fc8543;
		border: none;
	}
}

.btn-light {
	color: #E76923 !important;
	background: #FFEBE0 !important;
	border: 1.5px solid #E76923 !important;
}

.text-2xs {
	font-size: 10px;
}

.text-xs {
	font-size: 12px;
}

.text-sm {
	font-size: 14px;
}

.text-md {
	font-size: 16px;
}

.text-lg {
	font-size: 18px;
}

.text-xl {
	font-size: 20px;
}

.text-2xl {
	font-size: 24px;
}

.text-3xl {
	font-size: 28px;
}

.doc-image {
	width: 18px;
}

.pl-grey-btn {

	&:hover,
	&:focus {
		background: #FFFFFF;
		color: #3B3B3C;
		border-color: #3B3B3C;
		border-radius: 4px;
	}

	background: #FFFFFF;
	color: #3B3B3C;
	border-color: #3B3B3C;
	border-radius: 4px;
}

.document-breadcrumb {
	padding-bottom: 15px;
}

.ps-auto {
	cursor: pointer;
}

.dsbt {
	.ant-tabs-nav-wrap {
		justify-content: flex-start !important;
	}
}

.upload-root {
    .ant-upload {
	border: 1px solid #D3D3D3;
	border-radius: 4px;
	flex-direction: column;
	display: flex;
	align-items: center;
	justify-content: center;
	height: 113px;
      width: 100%;
    }
}

.sub-task .ant-drawer-content-wrapper {
	width: 600px !important;
	.ant-drawer-header {
		padding: 8px 20px;
		border-bottom: none;
	}
	.ant-drawer-body {
		padding: 3px 24px 10px;
	}
	.ant-form-item.upload-custom-field {
		.ant-form-item-control {
		  border: 1px solid #ececec !important;
		  border-radius: 4px;
		  background: #fff;
		  padding: 0 10px 30px;
		}
	  }
	  .pl-primary-btn {
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 18px;
    }

}

.dwr-rt {
    .ant-form-item-label {
      > label {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: #909090;
      }
    }
	.ant-drawer-header-title {
		div {
			order: 1;
		}
		button {
			order: 2;
		}
	}
	.ant-form-item-required:not(.ant-form-item-required-mark-optional) {
		&:before {
		  display: none !important;
		}
		&:after {
		  display: inline-block;
		  margin-left: 4px;
		  color: #ff4d4f;
		  font-size: 12px;
		  font-family: SimSun, sans-serif;
		  line-height: 1;
		  content: '*';
		}
	  }
}
.primary-outline, .primary-outline:hover, .primary-outline:focus {
	color: #FC8543;
	background: #FFFFFF;
	font-size: 14px !important;
	font-weight: 500;
	line-height: 18px; /* 128.571% */
	letter-spacing: -0.084px;
}
.svc-creator {
	height: calc(100vh - 64px) !important;
}

.drawing-success {
	background: #fff8ea !important;
	width: 450px;
	.ant-notification-notice-description{
		margin-left: 0;
	}
	.ant-notification-notice-close{
		position: absolute !important;
		color: #D69B25;
	}
	.ant-notification-notice-icon{
		position: absolute !important;
		top: 13px;
		left: 7px;
	}
	.ant-notification-notice-content {
		margin-left: 7px;
	}
}
.ant-select-tree-checkbox.ant-select-tree-checkbox-disabled {
    display: none;
}

li.ant-select-tree-treenode-disabled > span:not(.ant-select-tree-switcher),
li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper,
li.ant-select-tree-treenode-disabled > .ant-select-tree-node-content-wrapper span {
	cursor: default;
}

.ant-select-tree-treenode {
	align-items: center !important;
}

li.ant-select-tree-treenode-disabled.ant-select-tree-treenode-switcher-close {
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	background-color: white;
	z-index: 1;
	margin-top: 0;
	padding-top: 4px;
}
.ant-select-tree-treenode:not(:first-child) {
	border-top: 2px solid #F6F6F6;
}
.cancel-btn {
	background: #ececec !important;
	color: #5C5C5C !important;
	border: 1px solid #ececec;
	height: 40px;
	font-weight: 700;
	&:hover, &:focus, &:active {
		background: #ececec !important;
		color: #5C5C5C !important;
		border: 1px solid #ececec;
	}
}
.dark-btn {
	background: #5C5C5C !important;
	color: #fff !important;
	border: 1px solid #5C5C5C;
	height: 40px;
	font-weight: 700;
	&:hover, &:focus, &:active {
		background: #5C5C5C !important;
		color: #fff !important;
		border: 1px solid #5C5C5C;
	}
}
.pipeline-info-btn {
	background: #fff !important;
	color: #2A70D9 !important;
	border: 1px solid #2A70D9;
	height: 32px;
	line-height: normal;
	padding: 8px 16px !important;
	font-weight: 500;
    font-size: 14px;
	&:hover, &:focus, &:active {
		background: #fff !important;
		color: #2A70D9 !important;
		border: 1px solid #2A70D9;
	}
}
.defineProperty {
	.text-left,  .text-right {
		display:flex;
	}
	.text-left {
		text-align: left;
		align-items: center;
		justify-content: start;
	}
	.text-right {
		align-items: center;
		justify-content: end;
	}
	.user-name {
		h2 {
			text-overflow: ellipsis;
			overflow: hidden;
			width: 160px;
			white-space: nowrap;
		}
	}
	.close-btn {
		background: #ececec !important;
		color: #909090 !important;
		border: 1px solid #ececec;
	}
	.doc-page {
		background: #f6f6f6;
		border: 1px solid #f6f6f6;
		padding: 5px 10px;
		text-align: center;
		border-radius: 5px;
		margin-top: 5px;
		.grey-text {
			color: #909090;
		}
		h2 {
			font-size: 18px;
			color: #909090;
			margin-bottom: 0;
			span {
				display: block;
				font-size: 12px;
			}
		}
		.normal{
			color: #D69B25
		}
		.complete {
			display: flex;
			align-items:center;
			padding: 5px 10px;
			background: #E5F8E9;
			border-radius: 15px;
			color: #009E1F;
			svg {
				width: 22px;
			}
		}
	}
	.doc-page.selected {
		background: #fff6f0;
		border: 1px solid #fc8543;
		.grey-text {
			color: #080808;
		}
		h2 {
			color: #080808;
		}
	}
}
.toolbar {
	margin-top: auto;
	order: 2;
}

@media print {
	body {
	  visibility: hidden;
	  height: 99%;
	  page-break-after: avoid;
	  page-break-before: avoid;
	}
	@page {
		size: landscape;
	  }
	.print-only  {
	  visibility: visible;
	  width: 210mm;
    height: 297mm;
	page-break-before: always !important;
	}
  }
$color_1: #009e1f;
$color_2: #5E5D5D;

.btn-save {
	color: $color_1;
	border: 1px solid #009e1f;
	background: #e5f8e9;
	&:hover {
		color: $color_1;
		border: 1px solid #009e1f;
		background: #e5f8e9;
	}
	&:focus {
		color: $color_1;
		border: 1px solid #009e1f;
		background: #e5f8e9;
	}
	&:active {
		color: $color_1;
		border: 1px solid #009e1f;
		background: #e5f8e9;
	}
}
.btn-secondary {
	color: $color_2;
	border: 1px solid $color_2;
	background: #FFF;
	&:hover {
		color: $color_2;
		border: 1px solid $color_2;
		background: #FFF;
	}
	&:focus {
		color: $color_2;
		border: 1px solid $color_2;
		background: #FFF;
	}
	&:active {
		color: $color_2;
		border: 1px solid $color_2;
		background: #FFF;
	}
}
.align-items-center {
    -ms-flex-align: center!important;
    align-items: center!important;
}
.cancel-btn {
	background-color: #D3D3D3 !important;
	color: #5C5C5C !important;
	border: 1px solid #D3D3D3 !important;
	&:hover {
		border: 1px solid #D3D3D3 !important;
	}
	&:focus {
		border: 1px solid #D3D3D3;
	}
	&:active {
		border: 1px solid #D3D3D3;
	}
}
.info-btn {
	svg {
		width: 20px;
		height: 20px;
		padding-right: 5px;
	}
	border-radius: 5px;
	font-size: 12px;
	margin-top: 5px;
	padding: 5px 7px 5px 5px;
	background-color: #EBF3FF !important;
	color: #3284FF !important;
	border: 1px solid #3284FF !important;
	&:hover {
		border: 1px solid #3284FF !important;
	}
	&:focus {
		border: 1px solid #3284FF;
	}
	&:active {
		border: 1px solid #3284FF;
	}
}

.ant-select-selection-placeholder{
	color : #909090 !important;
	font-style: italic !important;
  }
  input::placeholder {
	color : #909090 !important;
	font-style: italic !important;
  }
svg:hover {
	path{
		// stroke: #FC8543;
	}
	// fill: #FFF6F0;
  }

  .crew-more-dropdown {
	    .ant-dropdown-menu-item {
            padding: 5px 10px;
        }
  }

  .fl-caps {
	text-transform: capitalize;
  }
  .custom-form-item label.ant-form-item-required {
	&::after {
		display: inline-block;
		margin-right: 4px;
		color: #ff4d4f;
		font-size: 14px;
		font-family: SimSun, sans-serif;
		line-height: 1;
		content: '*';
	}
	&::before{
		display: none !important;
	}
	// float: right;
	margin-left: 4px; /* Adjust the margin as needed */
  }
  .custom-ribbons-container .ribbons-container .ribbons .ribbon-group, .Panel.customPanel {
	font-family: Inter !important;
}
.mapboxgl-ctrl-top-right {
	display: none;
}
.drawing-select {
	background: #f8f9fa;

	.ant-select-selector {
		border: none !important;
		background-color: #f8f9fa !important;
		box-shadow: none !important;

		.ant-select-selection-item {
			font-size: 13px;
			font-style: normal;
			font-weight: 500;
			line-height: 24px;
			letter-spacing: -0.176px;
			color: #5C5C5C;
		}
	}
}

.drawer {
	.list-group {
		.list-group-item {
			border-radius: 4px;
			border: 1px solid #D3D3D3;
			display: flex;
			padding: 12px 0px 12px 12px;
			align-items: center;
			gap: 12px;
			background: #fff;
			margin-bottom: 5px;
			// svg {
			// 	fill: #868e96;
			// }
		}
	}
}

.drawing-preview-header {
	padding: 0 !important;
	padding-top: 0 !important;
	padding-left: 15px !important;
	background: #f8f9fa;
}
.ant-upload-drag.ant-form-item-explain-error {
	border: 1px solid #ff4d4f
}
.annotation-hidden {
	display: none !important;
  }
  .create-punchlist-modal {
    .ant-modal-title {
      display: flex;
    }
  }
  .download-file {
	padding-left: 0;
	span {
		text-decoration: underline;
		color: #FC8543 !important;
		padding-left: 5px;
	}
  }
  .ant-table-pagination.ant-pagination {
    padding: 16px 16px 16px 0;
    margin: 0 0 16px 0;
	border-bottom-left-radius: 5px;
	border-bottom-right-radius: 5px;
	background-color: #ececec;
}
.basic-table {
	.ant-table-pagination.ant-pagination {
		background: none !important;
	}
}
.ant-dropdown-menu-sub {
	max-height: 300px;
	overflow-y: scroll;
}

.center-image {
	text-align: center;
	background: rgb(255, 255, 255);
    justify-content: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
	.no-page-title {
		color: rgb(8, 8, 8);
		font-size: 20px;
		font-style: normal;
		font-weight: 700;
		line-height: 32px; /* 160% */
		letter-spacing: -0.34px;
		margin: 10px 0px;
		padding: 10px 0;
	}
	.go-back-btn {
		height: 42px;
		font-size: 16px;
		font-style: normal;
		font-weight: 700;
		line-height: 24px; /* 150% */
		letter-spacing: -0.176px;
	}
}
.action-icon {
	width: 30px;
}
.punchlist-sidebar {
	.ant-menu-title-content { display: none; }
}
.punchList {
	.ant-dropdown-menu-submenu-expand-icon {
		top: 8px;
	}
}
.location-dropdown {
	.ant-select-tree-switcher-noop {
		display: none;
	}
	.ant-select-tree-title {
		display: flex;
		align-items: center;
		padding-block: 8px;
	}
	.h-24 {
		height: 24px;
	}
	.ant-select-tree .ant-select-tree-treenode {
		padding: 0;
	}
}
.table-text {
	text-transform: none !important;
  }
  
.table-text::first-letter {
	text-transform: uppercase !important;
}

.btn-primary {
	height: 40px;
	padding: 8px 16px;
}
.rfi-select {
	.ant-select-selection-item {
		display: inline-block;
		width: 180px;
		white-space: nowrap;
		overflow: hidden !important;
		text-overflow: ellipsis;
	}
}

.content {
	padding: 20px;
  }
  
  .add-comment {
	margin-top: 20px;
  }
  
  .add-comment .ant-input {
	width: 100%;
  }
  
  .add-comment .ant-btn {
	margin-top: 10px;
  }
.custom-confirm-modal {
	.ant-modal-confirm-body {
		display: flex;      
		flex-direction: column; 
		justify-content: center;
		align-items: center;
		gap: 7px;
	}
	.ant-modal-confirm-btns {
		display: flex;
		justify-content: center;
		align-items: center;
		.ant-btn-primary {
			background-color: #D92929;
		}
	}
}

.td-ellipse {
	text-overflow: ellipsis;
	overflow: hidden;
	max-width: 190px;
	white-space: nowrap;
}