@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700&display=swap');
:root {
  --grey-1: #f8f9fa;
  --grey-2: #f1f3f5;
  --grey-3: #e7ebee;
  --grey-4: #dee2e6;
  --grey-5: #cfd4da;
  --divider: var(--grey-5);
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Inter',Arial, Helvetica, sans-serif;
}

body {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
body::-webkit-scrollbar {
  display: none;  /* Safari and Chrome */
}
* {
  box-sizing: border-box;
}

.webviewer {
  flex: 1;
  margin: 8px;
  -webkit-box-shadow: 1px 1px 10px #999;
  box-shadow: 1px 1px 10px #999;
}

.divider {
  width: 100%;
  margin: 16px 0;
  border-top: 1px solid var(--divider);
}